.powered-by {
  background-color: var(--color-black);
  color: var(--color-white);

  > p {
    > a {
      transition: all 0.15s ease-in-out;

      &:hover, &:active {
        color: var(--color-peach) !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
}