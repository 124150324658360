.GeneratorBtn {
  background: #e57d60;
  border-radius: 30px;
  color: #000;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    border: 1.5px solid #000;
    border-radius: 30px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  // only when hover is possible
  @media (hover: hover) {
    &:before {
      animation: 0.15s ease-in-out forwards out;
    }

    &:hover {
      &:before {
        animation: 0.15s ease-in-out forwards in;
      }
    }

    @keyframes in {
      from {
        left: 5px;
        top: 5px;
      }
      to {
        left: 0;
        top: 0;
      }
    }

    @keyframes out {
      from {
        left: 0;
        top: 0;
      }
      to {
        left: 5px;
        top: 5px;
      }
    }
  }
}