.App {
    height: 90%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.75rem;
    overflow: hidden;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @media screen and (max-width: 576px) {
        //min-height: 100vh;
        //min-width: 100%;
        height: 100%;
        min-width: 100%;
        backdrop-filter: blur(4px);
        border-radius: 0;
    }

    .Generator,
    .Favorites,
    .Infos {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50%;

        .GeneratedList,
        .FavoritesList {
            list-style-type: none;

            >li {
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                border-radius: 0.5rem;
                background-color: var(--color-white);
                box-shadow: 0 2px 12px -10px var(--color-black);
            }
        }
    }

    .Presentation,
    .Favorites-info,
    .NoContent,
    .Info-content {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            border: 2px solid white;
            transform: rotate(357deg);
            border-radius: 0.3rem;
            z-index: 0;

            @media screen and (max-width: 576px) {
                transform: rotate(358deg);
            }
        }
    }

    .Infos {
        @media screen and (max-width: 576px) {
            &::before {
                transform: rotate(359deg);
            }
        }
    }
}