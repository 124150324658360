.InfoIcon {
  //> .cls-1 {
  //  fill: #fff;
  //  stroke: #3a2e39;
  //  stroke-miterlimit: 10;
  //}

  >.cls-1 {
    fill: #fff;
    stroke: #3a2e39;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
  }
}