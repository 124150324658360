.Nav {
  background-color: var(--color-peach);

  > a {
    width: 4rem;
    height: 2rem;
    display: flex;

    > svg {
      margin: auto;

      > .cls-1 {
        animation: unactive 0.15s ease-in-out forwards;
      }
    }

    &.active, &:hover, &:active {
      > svg > .cls-1 {
        animation: active 0.15s ease-in-out forwards;
      }
    }

    @keyframes active {
      from {
        fill: #fff;
      }
      to {
        fill: var(--color-black);
      }
    }

    @keyframes unactive {
      from {
        fill: var(--color-black);
      }
      to {
        fill: #fff;
      }
    }
  }
}