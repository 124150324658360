.Infos {
    >.Info-container {
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: rgba(0, 0, 0, 0.075);

        >.Info-content {
            >.content {
                z-index: 1;

                > p > a {
                    transition: all 0.15s ease-in-out;

                    &:hover, &:active {
                        color: #e68067 !important;
                    }
                }
            }
        }
    }
}