.ShareApiBtn {
    width: 32px;
    height: 32px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: auto 0;

    >svg {
        font-size: 0.9em;
    }
}