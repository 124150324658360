.HomeIcon {
  //> .cls-1 {
  //  fill: #3a2e39;
  //}

  > .cls-1 {
    fill: #fff;
  }

  > .cls-2 {
    fill: #3a2e39;
  }
}